import React from 'react';
import PropTypes from 'prop-types';
import Icon from '~2-components/IconInline/IconInline';
import IconIllustration from '~2-components/IconIllustration/IconIllustration';
import formatPhoneNumber from '~utils/formatPhoneNumber';
import { Link } from 'gatsby';

const ProfileTile = (props) => {
    const{ imgUrl, imgAlt, accredited, firstName, lastName, position, phone, email, linkedIn, urlSlug, cmsImage} = props;
    let formattedNumber = null;
    if(phone){
        formattedNumber = formatPhoneNumber(phone);
    }
    return (
        <Link to={`/${urlSlug}`} className="profile-tile">
            <div className="profile-image">
                {cmsImage && cmsImage}
                {imgUrl &&
                    <img src={imgUrl} alt={imgAlt} />
                }
            </div>
            {accredited === 'yes' &&
                <div className="profile-accreditation">
                    <IconIllustration
                        icon={'accreditation'}
                    />
                </div>
            }
            <h5 className="profile-name">{firstName} {lastName}</h5>
            <p className="profile-position">{position}</p>
            <div className="contact-ctas">
                {phone &&
                    // <a href={`tel:${formattedNumber}`}>
                    //     <Icon icon={'phone'} />
                    // </a>
                    <span className="cta-icon">
                        <Icon icon={'phone'} />
                    </span>
                }
                {email &&
                    // <a href={`mailto:${email}`}>
                    //     <Icon icon={'envelope'} />
                    // </a>
                    <span className="cta-icon">
                        <Icon icon={'envelope'} />
                    </span>
                }
                {linkedIn &&
                    // <a href={linkedIn}>
                    //     <Icon icon={'linkedin'} />
                    // </a>
                    <span className="cta-icon">
                        <Icon icon={'linkedin'} />
                    </span>
                }
            </div>
            {urlSlug &&
                // <Link to={`/${urlSlug}`} className="profile-link">View profile</Link>
                <div className="profile-link">View profile</div>
            }
        </Link>
    );
};

export default ProfileTile;

ProfileTile.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    imgUrl: PropTypes.string,
    imgAlt: PropTypes.string,
    accredited: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    position: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    linkedIn: PropTypes.string,
    urlSlug: PropTypes.string,
    cmsImage: PropTypes.object
};